<template>
  <div>
    <header class="position-relative">
      <div class="page-header min-vh-100 position-relative">
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
          loading="lazy"
        >
          <source src="@/assets/video/igrala_video.mp4" type="video/mp4" />
        </video>
        <span class="mask bg-gradient-light"></span>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center mx-auto mt-n7">
              <h2 class="text-success animate__animated animate__bounce mb-3">
                Izposoja napihljivih igral
              </h2>
              <h1 class="text-primary animate__animated animate__bounce">
                Napihljiva igrala Dioniz
              </h1>
              <!--<p
                class="lead mb-5 fadeIn3 fadeInBottom text-white opacity-8 w-50 mx-auto mt-4"
              >
                Carefully tailored financial education with guidance to
                financial security and generational wealth is our specialty.
              </p>-->
            </div>
          </div>
          <div class="row justify-content-center mt-5">
            <div class="col-6">
              <img
                src="@/assets/images/napihljiva_igrala_dioniz_2.png"
                class="img-fluid animate__animated animate__bounce"
              />
            </div>
            <div class="col-6">
              <img
                src="@/assets/images/napihljiva_igrala_dioniz_1.png"
                class="img-fluid animate__animated animate__bounce"
              />
            </div>
          </div>
          <div class="row justify-content-center mt-5">
            <div class="col-12 text-center">
              <h3 class="mb-3">
                Rezervirajte na:
                <a href="tel:+38641704268" class="text-primary">041 704 268</a>
                ali
                <a
                  href="mailto:boris.nedoh@gmail.com"
                  target="_blank"
                  class="text-info"
                  >boris.nedoh@gmail.com</a
                >
              </h3>
              <a v-scroll-to="'#more'" class="btn btn-lg btn-info"
                ><font-awesome-icon icon="circle-down" class="me-2" />Več</a
              >
            </div>
          </div>
        </div>
      </div>
    </header>

    <section id="more" style="background-color: #fde3a1">
      <div class="container">
        <div class="row align-items-center pt-5 pb-4 text-center">
          <h4>
            Cena najema za eno igralo znaša<br />
            <span class="display-4 text-primary">70 EUR na dan</span>, <br />
            oz.
            <span class="display-4 text-success"
              ><strong>120 EUR za cel vikend</strong></span
            >
          </h4>
          <p class="text-dark my-2 display-5">Dostava in prevzem po dogovoru</p>
          <p class="text-larger text-dark fw-bold mt-2">
            Enodnevni najem je možen od ponedeljka do četrtka. Igrala niso
            primerna za večje javne prireditve. Priporočamo jih za manjše
            zasebne zabave. Igranje otrok je na lastno odgovornost.
          </p>
        </div>
        <div class="row align-items-center pt-3 pb-4">
          <div class="col-lg-6 text-center h-100 mb-2">
            <div class="card border-0 h-100 shadow-sm">
              <h3 class="card-header font-primary p-4 color1">
                Napihljivi TOBOGAN
              </h3>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  Dimenzije: <strong>600 x 215 x 285</strong>
                </li>
                <li class="list-group-item">
                  Primerno za: <strong>3 - 9 let</strong>
                </li>
                <li class="list-group-item">
                  Max št. otrok na igralu: <strong>4</strong>
                </li>
              </ul>
              <div class="card-body">
                <img
                  src="@/assets/images/napihljiva_igrala_dioniz_4.png"
                  class="img-fluid my-3 px-3"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 text-center h-100 mb-2">
            <div class="card border-0 h-100 shadow-sm">
              <h3 class="card-header font-primary p-4 color1">
                Napihljive OVIRE
              </h3>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  Dimenzije: <strong>530 x 250 x 215</strong>
                </li>
                <li class="list-group-item">
                  Primerno za: <strong>3 - 9 let</strong>
                </li>
                <li class="list-group-item">
                  Max št. otrok na igralu: <strong>5</strong>
                </li>
              </ul>
              <div class="card-body">
                <img
                  src="@/assets/images/napihljiva_igrala_dioniz_3.png"
                  class="img-fluid my-3 px-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {},
  methods: {
    bgr_img: function () {
      let img = require("@/assets/css/images/hero-bg-icons.svg");
      console.log(img);
      return `background: transparent url('${img}') center center / cover; opacity: 0.04;`;
    },
  },
};
</script>
