<template>
  <div id="app">
    <div id="wrapper" class="clearfix">
      <router-view />
    </div>
  </div>
</template>
<script>
import "@/assets/css/nucleo-icons.css";
import "@/assets/css/material-kit-pro.css";
import "@/assets/css/kindergarten.css";
import "@/assets/css/fonts.css";
import "@/assets/css/dark.css";
import "@/assets/css/swiper.css";

export default {
  name: "App",
  components: {},
  computed: {},
};
</script>
